import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import styled from 'styled-components'

const BlockContainer = styled.section`
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	/* grid-template-rows: minmax(auto, max-content); */
	grid-column-gap: 24px;
	/* border: 1px solid red; */

	& > * {
		grid-column: 1 / span 12;
		grid-row: 1;
		max-height: 50vh;
		/* border: 1px solid blue; */
	}

	@media (min-width: 576px) {
	}

	@media (min-width: 768px) {
		& > * {
			grid-column: 2 / span 10;
		}
	}

	@media (min-width: 992px) {
	}

	@media (min-width: 1200px) {
		& > * {
			grid-column: 3 / span 8;
		}
	}
`

const ImageSingleBlock = props => (
	<BlockContainer>
		<Img
			fluid={props.imageData.childImageSharp.fluid}
			alt={props.altText}
			imgStyle={props.contain === true ? { objectFit: 'contain' } : null}
		/>
	</BlockContainer>
)
export default ImageSingleBlock

ImageSingleBlock.propTypes = {
	imageData: PropTypes.shape({
		childImageSharp: PropTypes.shape({
			fluid: PropTypes.object.isRequired
		}).isRequired
	}).isRequired,
	altText: PropTypes.string.isRequired,
	contain: PropTypes.bool
}

ImageSingleBlock.defaultProps = {
	altText: 'Placeholder Alt Text',
	contain: false
}
