import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import SVGSeparator from '../components/project-blocks/separator'
import InformationSectionBlock from '../components/project-blocks/information-section'
import TextSingleBlock from '../components/project-blocks/text-single'
import TextLargeAndSmallBlock from '../components/project-blocks/text-large-and-small'
import ImageSingleBlock from '../components/project-blocks/image-single'
import ImageDoubleBlock from '../components/project-blocks/image-double'
import TextAndImageBlock from '../components/project-blocks/text-and-image'

import { informationSectionData } from '../data/case-study-data--castle'

// import castleHeroImage from '../images/castle--hero-image.svg' // used
// import castleManImage from '../images/castle--man.svg' // unused
// import castleBrainstorming from '../images/castle--brainstorming.png' // unused
// import castleDefinitionPlaceholder from '../images/castle--definition-placeholder.png'
// import castleBenchmark from '../images/castle--benchmark-analysis.png'
// import castleInteractionMap from '../images/castle--interaction-maps.png'
// import castleWireframesBefore from '../images/castle--wireframes-before.png'
// import castleSideDrawer from '../images/castle--side-drawer.png'
// import castleOldOnboarding from '../images/castle--old-onboarding.png'
// import castleNewOnboarding from '../images/castle--new-onboarding.png'
// import castleOldSettings from '../images/castle--old-settings.png'
// import castleNewSettings from '../images/castle--new-settings.png'
// import castleManHooray from '../images/castle--man-hooray.svg'

// import ProjectStyles from '../components/project-styles.module.less'

const HeaderImage = styled.div`
	height: auto;

	margin-bottom: 12px;

	& > * {
		width: auto;
		max-height: 100vh;
		/* height: fill-available; */
	}
`

const HeadingGroup = styled.header`
	display: grid;
	grid-gap: 6px;
`

const Title = styled.h1`
	font-size: 24px;
	font-weight: 500;
	letter-spacing: 1px;

	@media (min-width: 576px) {
	}

	@media (min-width: 768px) {
		font-size: 32px;
	}

	@media (min-width: 992px) {
	}

	@media (min-width: 1200px) {
	}
`

const Subtitle = styled.h2`
	font-size: 18px;
	opacity: 0.75;

	@media (min-width: 768px) {
		font-size: 21px;
	}
`

const Castle = ({ data }) => (
	<Layout>
		<SEO title="Castle UX / UI Case Study" />
		<HeaderImage>
			<Img
				fluid={data.heroImage.childImageSharp.fluid}
				alt="Castle's Logo."
				imgStyle={{ objectFit: 'cover' }}
			/>
		</HeaderImage>
		<HeadingGroup>
			<Title>Castle</Title>
			<Subtitle>UX/UI Case Study</Subtitle>
		</HeadingGroup>
		<SVGSeparator />
		<InformationSectionBlock data={informationSectionData} />
		<SVGSeparator />
		<TextSingleBlock
			heading={'The Problem'}
			paragraphs={[
				'Canadians are spending more and more of their lives on mobile devices when not on home or work Wi-Fi. The lifeblood of using these devices is, of course, mobile data. Thanks to the oligopoly of “the big 3” that data remains very expensive and monthly allowances remain frustratingly small.',
				'At the same time, Wi-Fi networks are prevalent in many areas providing internet data that is relatively cheap, faster speeds, and has greatly higher bandwidth	allowances available.',
				'How can we help bridge this gap between providers of fast, cheap Wi-Fi with consumers who want that same product?'
			]}
		/>
		<SVGSeparator />
		<ImageSingleBlock
			imageData={data.mockupThreeScreens}
			altText={'A placeholder image for some app screens.'}
		/>
		<SVGSeparator />
		<TextLargeAndSmallBlock
			heading={'The Process'}
			paragraphs={[
				'For this project, we got to experience an end-to-end waterfall model of designing an app. This means we gathered the requirements from the brief, analyzed the design goals, performed research into similar desgin solutions, the audience, and others, followed by development of a solution, and then testing that solution with user-testing sessions.'
			]}
		/>
		<SVGSeparator />
		<TextAndImageBlock
			heading={'Project Definition'}
			paragraphs={[
				'After the idea was brainstormed, the next step is a series of project definition steps. The first was a PACT analysis, which identifies the key users through breaking things down into people, activities, context, and technology. The second was scenarios, which are fictional but plausible user profiles as they interact with the app. Lastly, is to define requirements which prioritizes the app’s features usually through the MoSCoW method or similar.'
			]}
			imageData={data.projectDefinition}
			altText={'A placeholder image for some app screens.'}
		/>
		<SVGSeparator />
		<TextAndImageBlock
			heading={'Benchmark Analysis'}
			paragraphs={[
				'Once the project was defined, a benchmark analysis was performed. This was an important step that gathered research from other similar designs and analyzed them for their visual design, navigation, interactions to see if they could work for the app we were designing. For example, one of the apps we found was similar to ours was the Uber app. We determined that having a opening screen with a large map emphasized the interaction to connect and explore which would increase engagement to our app and service.'
			]}
			imageData={data.benchmarkAnalysis}
			altText={'A placeholder image for some app screens.'}
			switch={true}
		/>
		<SVGSeparator />
		<TextAndImageBlock
			heading={'Interaction Maps'}
			paragraphs={[
				'One of the best tools for determining the screens and interactions we needed to make the design work was an simple interaction map made with sticky notes and markers. This map specifically helps organized the consumption and provider sides of the app; similar to AirBnB’s model. Interaction maps are a great way of finding the business model as well but is indispensable for the next step, prototyping.'
			]}
			imageData={data.interactionMap}
			altText={'A placeholder image for some app screens.'}
		/>
		<SVGSeparator />
		<TextAndImageBlock
			heading={'Prototyping'}
			paragraphs={[
				'After all of the preparation, research, analysis, vectors are put to the artboards! Here we applied what we learned and created a prototype to help users connect to the service quickly, easily, and without too many pain points. We went through a few revisions and ideas but quickly the main prototype was ready to be user tested on our audience.'
			]}
			imageData={data.prototyping}
			altText={'A placeholder image for some app screens.'}
			switch={true}
			link={{
				label: 'Initial High Fidelity Figma Prototype',
				url:
					'https://www.figma.com/proto/NXqky2N3XDUMEUumLcrjLSzQ/Castle-Hi-Fi-Wireframes'
			}}
		/>
		<SVGSeparator />
		<TextAndImageBlock
			heading={'User Testing'}
			paragraphs={[
				'The first real test of our app design is user testing. Here we tested the prototype with three individuals who all had valuable and sometimes unexpected insights into the current design. For example, one user knew that they could also sell Wi-Fi as well as buying it but couldn’t determine how to do that. In that prototype we included a fly-out drawer with many additional options but it wasn’t immediately obvious. Overall, the user testing was invaluable and should be done as soon as possible to reorient future design refinements toward serving actual users much better.'
			]}
			imageData={data.userTesting}
			altText={'A placeholder image for some app screens.'}
		/>
		<SVGSeparator />
		<TextLargeAndSmallBlock
			heading={'Refinements'}
			paragraphs={[
				'There were countless refinements between the prototype we user tested and the final design for the service. Here are some below —'
			]}
		/>
		<SVGSeparator />
		<ImageDoubleBlock
			imageData={[data.onboardingOld, data.onboardingNew]}
			altText={['An image I\'m testing.', 'Another image I\'m testing.']}
			contain={true}
		/>
		<TextSingleBlock
			paragraphs={[
				'The first refinements went to the onboarding experience with a much more engaging visual design and workflow with only the essentials to get people going.'
			]}
		/>
		<SVGSeparator />
		<ImageDoubleBlock
			imageData={[data.settingsOld, data.settingsNew]}
			altText={['An image I\'m testing.', 'Another image I\'m testing.']}
			contain={true}
		/>
		<TextSingleBlock
			paragraphs={[
				'The second set of refinements went the secondary actions screens. For the first prototype we used a side drawer metaphor to list the additional actions. This didn’t work during the user testing so this lead us to implementing an overlay which organized things much more cleanly and adding a findable toggle to the top.'
			]}
		/>
		<SVGSeparator />
		<TextAndImageBlock
			heading={'The Solution'}
			paragraphs={[
				'The original problem we wanted to solve was that Canadians only have expensive and limited data plans available to us as consumers. This app addresses that problem and adds several other features to promote app growth and retains users.',
				'One decision we made based on feedback was to separate the buying and selling of Wi-Fi data into two sections of the app. For buying Wi-Fi, the user wants to connect quickly and easily and accomplish their task. For selling Wi-Fi, analytics about other users’ use and how much money they’ve made came to the forefront of the design.',
				'The biggest takeaway from this process was the value of user testing on a wide diversity of people in your audience. For example, this app had an audience of mobile device users between the ages of 16 to 65. Addressing that large age range through one user experience was challenging. Luckily, we were able to test the prototype with a wide range of ages and that enabled us to change the app’s UI/UX to address those needs.'
			]}
			imageData={data.castleManHooray}
			altText={'Castle\'s mascot with arms raised in joy.'}
			link={{
				label: 'Final Figma Prototype',
				url:
					'https://www.figma.com/proto/NXqky2N3XDUMEUumLcrjLSzQ/Castle-Hi-Fi-Wireframes?node-id=321%3A1300&scaling=min-zoom&redirected=1'
			}}
		/>
		<SVGSeparator />
		<Link to="/">← Back</Link>
	</Layout>
)

Castle.propTypes = {
	data: PropTypes.any.isRequired
}

export const pageQuery = graphql`
	query {
		heroImage: file(relativePath: { regex: "/castle--mockup-ten-screens/" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		mockupThreeScreens: file(
			relativePath: { regex: "/castle--mockup-three-screens/" }
		) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		projectDefinition: file(
			relativePath: { regex: "/castle--definition-placeholder/" }
		) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		benchmarkAnalysis: file(
			relativePath: { regex: "/castle--benchmark-analysis/" }
		) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		interactionMap: file(
			relativePath: { regex: "/castle--interaction-maps/" }
		) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		prototyping: file(relativePath: { regex: "/castle--wireframes-before/" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		userTesting: file(relativePath: { regex: "/castle--side-drawer/" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		onboardingOld: file(relativePath: { regex: "/castle--old-onboarding/" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		onboardingNew: file(relativePath: { regex: "/castle--new-onboarding/" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		settingsOld: file(relativePath: { regex: "/castle--old-settings/" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		settingsNew: file(relativePath: { regex: "/castle--new-settings/" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		castleManHooray: file(relativePath: { regex: "/castle--man-hooray.png/" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`

export default Castle
