import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const BlockContainer = styled.section`
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-auto-rows: min-content;
	grid-gap: 12px;

	& > * {
		grid-column: 1 / span 12;
	}

	@media (min-width: 576px) {
		& > * {
			grid-column: 2 / span 10;
		}
	}
	@media (min-width: 768px) {
		/* grid-gap: 24px; */
		& > * {
			grid-column: 3 / span 8;
		}
	}
	@media (min-width: 992px) {
		& > * {
			grid-column: 4 / span 6;
		}
	}
	@media (min-width: 1200px) {
		& > * {
			grid-column: 4 / span 6;
		}
	}
`

const Heading = styled.h1`
	font-size: 18px;
	font-weight: 500;

	border-top: 2px solid var(--primary-colour);
	padding-top: 12px;

	@media (min-width: 768px) {
		font-size: 24px;
	}
`

const Paragraph = styled.p`
	font-size: 14px;

	line-height: 1.4;

	/* margin-top: 24px; */

	@media (min-width: 768px) {
		font-size: 16px;
	}
`

const LinkOutIcon = styled.svg`
	transform: translateY(3px);
	width: 16px;
	height: 16px;
	margin-left: 10px;

	& > g > polyline,
	& > g > line {
		fill: none;
		stroke: var(--primary-colour);
		stroke-miterlimit: 10;
	}
`

const TextSingleBlock = props => (
	<BlockContainer>
		{props.heading !== null ? <Heading>{props.heading}</Heading> : null}
		{props.paragraphs.map((paragraph, index) => (
			<Paragraph key={index}>{paragraph}</Paragraph>
		))}
		{props.link !== null ? (
			<Paragraph>
				<a href={props.link.url} name="link-box">
					{props.link.label}
					<LinkOutIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
						<g>
							<polyline points="15.5,8 15.5,15.5 0.5,15.5 0.5,0.5 8,0.5 	" />
						</g>
						<g>
							<polyline points="10.5,0.5 15.5,0.5 15.5,5.5 	" />
							<line x1="8.5" y1="7.5" x2="15.5" y2="0.5" />
						</g>
					</LinkOutIcon>
				</a>
			</Paragraph>
		) : null}
	</BlockContainer>
)

TextSingleBlock.propTypes = {
	heading: PropTypes.string,
	paragraphs: PropTypes.array,
	link: PropTypes.object
}

TextSingleBlock.defaultProps = {
	heading: null,
	paragraphs: [
		'In et similique libero dolores laborum. Voluptas nihil eius sit cum reiciendis. Voluptatum ad corporis quasi a id aut. Pariatur nulla est laborum ea ut et tempore. Et deserunt et ut ut ea qui asperiores. Atque quia unde occaecati.',
		'Tenetur iusto accusamus perspiciatis porro debitis neque dolor. Et iusto id quidem aut autem. Distinctio accusamus officiis aspernatur temporibus. Et id autem excepturi tempore. Nesciunt doloribus hic labore vel.'
	],
	link: null
}

export default TextSingleBlock
