import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import styled from 'styled-components'

const BlockContainer = styled.section`
	width: auto;
	max-width: 100%;
	height: auto;
	max-height: 50vh;
	padding: 0;
	margin: 0;
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	/* grid-template-rows: minmax(auto, 50vh); */
	grid-column-gap: 12px;
	/* border: 1px solid red; */

	& > * {
		grid-row: 1;
		grid-column: 1 / span 12;
		max-height: 50vh;
	}

	@media (min-width: 576px) {
	}

	@media (min-width: 768px) {
		& > *:nth-child(2) {
			grid-column: 1 / span 6;
		}

		& > *:last-child {
			grid-column: 7 / span 6;
		}
	}

	@media (min-width: 992px) {
	}

	@media (min-width: 1200px) {
	}
`

const ImageSwitcher = styled.nav`
	/* grid-column: 1 / span 12; */

	/* height: 100%; */
	z-index: 1000;

	display: flex;
	flex-direction: row;
	justify-content: space-between;

	@media (min-width: 768px) {
		display: none;
	}
`

const ArrowButtonLeft = styled.button`
	width: 44px;
	height: 44px;

	background: none;
	border: none;
	outline: none;
	stroke-width: 2px;

	align-self: center;

	& > svg {
		filter: drop-shadow(0 0 2px hsla(210, 100%, 50%, 0.5));
	}
`

const ArrowButtonRight = styled(ArrowButtonLeft)`
	transform: rotate(180deg);
`

const IndicatorLights = styled.div`
	align-self: flex-end;
`

export default class ImageDoubleBlock extends Component {
	constructor(props) {
		super(props)
		this.state = {
			topImage: 0
		}

		this.nextImage = this.nextImage.bind(this)
	}

	nextImage(e) {
		e.preventDefault()
		const currentImageNumber = this.state.topImage
		this.setState({
			topImage:
				currentImageNumber === this.props.imageData.length - 1
					? 0
					: currentImageNumber + 1
		})
	}

	previousImage(e) {
		e.preventDefault()
		const currentImageNumber = this.state.topImage
		this.setState({
			topImage:
				currentImageNumber === 0
					? this.props.imageData.length - 1
					: currentImageNumber - 1
		})
	}

	goToSpecificImage(e, index) {
		e.preventDefault()
		this.setState({
			topImage: index
		})
	}

	render() {
		return (
			<BlockContainer>
				<ImageSwitcher>
					<ArrowButtonLeft>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 32 32"
							onClick={e => this.previousImage(e)}
						>
							<polyline
								fill="none"
								stroke="#007EFF"
								strokeMiterlimit="10"
								points="21,28 9,16 21,4 "
							/>
						</svg>
					</ArrowButtonLeft>
					<IndicatorLights>
						{this.props.imageData.map((item, index) => (
							<svg
								key={index}
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 32 32"
								width="22"
								height="22"
								onClick={e => this.goToSpecificImage(e, index)}
							>
								<circle
									fill={this.state.topImage === index ? '#007EFF' : 'none'}
									stroke="#007EFF"
									strokeMiterlimit="10"
									cx="16"
									cy="16"
									r="8"
								/>
								<text>{item.childImageSharp.fluid.originalName}</text>
							</svg>
						))}
					</IndicatorLights>
					<ArrowButtonRight>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 32 32"
							onClick={e => this.nextImage(e)}
						>
							<polyline
								fill="none"
								stroke="#007EFF"
								strokeMiterlimit="10"
								points="21,28 9,16 21,4 "
							/>
						</svg>
					</ArrowButtonRight>
				</ImageSwitcher>

				{this.props.imageData.map((image, index) => (
					<Img
						key={index}
						fluid={image.childImageSharp.fluid}
						alt={this.props.altText[index]}
						imgStyle={
							this.props.contain === true ? { objectFit: 'contain' } : null
						}
						style={this.state.topImage === index ? { zIndex: '10' } : null}
					/>
				))}
			</BlockContainer>
		)
	}
}

ImageDoubleBlock.propTypes = {
	imageData: PropTypes.array.isRequired,
	altText: PropTypes.array.isRequired,
	contain: PropTypes.bool
}

ImageDoubleBlock.defaultProps = {
	altText: ['Placeholder Alt Text1', 'Placeholder Alt Text2'],
	contain: false
}
